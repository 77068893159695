import { Button } from 'antd';
import React from 'react';
import starx3 from '../../images/starx3.png';
import { PickerHeader } from '../';
import styles from './prePicker.module.css';
import moment from 'moment';
import gql from 'graphql-tag';
import { useBackendQuery } from '../../hooks/useBackendQuery';
import { useEvent } from '../../hooks';

const PreEventsPicker = () => {
  const { tbEvent } = useEvent()
  const { data } = useBackendQuery(gql`
    query {
      currentTBEvent {
        programItemTypeCount
      }
    }
  `);

  return (
    <div className={styles.preEventsPicker}>
      <PickerHeader />

      <div className="container-sm">
        <h3>
          <span className="turquoise">Techniek Bedrijven Evenement {moment(tbEvent?.date).format('YYYY')}</span>
        </h3>
        <p>
          Het {tbEvent?.name} op {moment(tbEvent?.date).format('DD MMMM')} zal bestaan uit{' '}
          {data?.currentTBEvent?.programItemTypeCount} bedrijfsonderdelen. We vragen je nu om per bedrijfsonderdeel je
          voorkeur uit te spreken.
        </p>
        <p>
          Op de bedrijvenpagina's vind je informatie over de bedrijven en de bedrijfsonderdelen die zij aanbieden. Deze
          informatie kan je helpen bij het kiezen van je voorkeuren.
        </p>
        <p>
          Zonder deze voorkeuren is je aanmelding voor het evenement niet compleet. Zorg ervoor dat je dit vóór{' '}
          {moment(tbEvent?.finalSignupDate).format('DD MMMM')} doet, zodat wij de beste match voor jou kunnen vinden!
        </p>
        <p className={styles.center}>
          <Button type="primary" href="/student/programma-invullen/" block style={{ maxWidth: 400 }}>
            Aan de slag
          </Button>
        </p>
      </div>
    </div>
  );
};

export default PreEventsPicker;

import React, { useEffect, useMemo } from 'react';
import {
  Form, Modal, Button, Input, Radio, Row, Col, Select, DatePicker, Checkbox, InputNumber,
} from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { gql } from '@apollo/client';
import { EducationalInstitution, Education } from '../../../utils';
import { useFormFieldProvider } from '../../../hooks';
import formStyles from '../../formComponents/formComponents.module.css';
import { useBackendQuery } from '../../../hooks/useBackendQuery';

const educationTypes = [
  { label: 'WO Bachelor', value: 'WO_BACHELOR' },
  { label: 'HBO Bachelor', value: 'HBO_BACHELOR' },
  { label: 'WO Master', value: 'WO_MASTER' },
  { label: 'Schakel', value: 'TRANSITION' },
  { label: 'Volledige studie in het buitenland', value: 'ABROAD' },
  { label: 'Anders', value: 'OTHERWISE' },
];

interface EducationalInstitutionsData {
  educationalInstitutions: [EducationalInstitution],
}

interface EducationsData {
  educations: [Education],
}

export interface StudiesModalProps {
  name: string,
  visible: boolean,
  value?: {
    i: number,
    item: object,
  },
  onCancel: () => void,
}

const EDUCATIONAL_INSTITUTIONS = gql`
  query($type: EducationType) {
    educationalInstitutions(where: { educationType: $type }) {
      name
      id
    }
  }
`;

const EDUCATIONS = gql`
  query($type: EducationType, $educationalInstitution: Int) {
    educations(where: { type: $type, educationalInstitutionId: $educationalInstitution }) {
      id
      name
      type
    }
  }
`;

const StudiesModal = ({
  name, visible, value, onCancel,
}: StudiesModalProps) => {
  const [form] = Form.useForm();
  const { onFieldsChange, useFormField, setFieldsValue } = useFormFieldProvider(form);

  useEffect(() => {
    if (!visible || !value?.item) {
      form.resetFields();
    } else if (value) {
      setFieldsValue(value.item);
    }
  }, [visible]);

  const [type] = useFormField(['type']);
  const [educationalInstitution] = useFormField(['educationalInstitution']);
  const [finished] = useFormField(['finished']);
  const [nominal] = useFormField(['nominal']);

  const { data: dataEducationalInstitutions } = useBackendQuery<EducationalInstitutionsData>(EDUCATIONAL_INSTITUTIONS, { variables: { type } });
  const educationalInstitutions = useMemo<EducationalInstitution[]>(() => (
    dataEducationalInstitutions?.educationalInstitutions || []
  ), [dataEducationalInstitutions]);

  const { data: dataEducations } = useBackendQuery<EducationsData>(EDUCATIONS, { variables: { type, educationalInstitution } });
  const educations = useMemo<Education[]>(() => (
    dataEducations?.educations || []
  ), [dataEducations]);

  const clearEducationFields = () => {
    form.setFieldsValue({
      educationalInstitution: null,
      educationalInstitutionName: null,
      education: null,
      educationName: null,
    });
  };

  const filterOption = (input: string, option: any) => new RegExp(input, 'gi').test(option.children);

  return (
    <Modal
      bodyStyle={{ backgroundColor: 'var(--secondary-900)' }}
      visible={visible}
      onCancel={onCancel}
      footer={null}
      width={800}
    >
      <Form className={formStyles.form} form={form} name={name} onFieldsChange={onFieldsChange}>
        <h3>
          <span className="turquoise">Studie toevoegen</span>
        </h3>
        <p className="infoBlock">
          Staat jouw studie er niet tussen kies dan “anders”,
          dan kan ons team gaan kijken of we je kunnen matchen met een van onze bedrijven.
        </p>

        <Form.Item
          label="Wat is het type opleiding?"
          name="type"
          rules={[{ required: true, message: 'Dit is een verplicht veld' }]}
        >
          <Radio.Group onChange={clearEducationFields}>
            <Row gutter={16}>
              {educationTypes.map((educationType) => (
                <Col key={educationType.value} md={12}>
                  <Radio value={educationType.value}>{educationType.label}</Radio>
                </Col>
              ))}
            </Row>
          </Radio.Group>
        </Form.Item>

        {!['WO_MASTER', 'TRANSITION'].includes(type) && (
          <Form.Item label="Welke minor heb je gevolgd?" name="minor">
            <Input type="text" />
          </Form.Item>
        )}

        {['ABROAD', 'OTHERWISE'].includes(type) ? (
          <>
            <Form.Item
              label="Naam onderwijsinstelling"
              name="educationalInstitutionName"
              rules={[{ required: true, message: 'Dit is een verplicht veld' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Naam opleiding"
              name="educationName"
              rules={[{ required: true, message: 'Dit is een verplicht veld' }]}
            >
              <Input />
            </Form.Item>
          </>
        ) : (
          <>
            <Form.Item
              label="Selecteer jouw onderwijsinstelling"
              name="educationalInstitution"
              rules={[{ required: true, message: 'Dit is een verplicht veld' }]}
            >
              <Select
                showSearch
                suffixIcon={(
                  <CaretDownOutlined style={{
                    color: '#fff', fontSize: 25, top: -7, left: -15, position: 'relative',
                  }}
                  />
                )}
                optionFilterProp="children"
                filterOption={filterOption}
              >
                {educationalInstitutions.map((institute) => (
                  <Select.Option key={institute.id} value={institute.id}>
                    {institute.name || ''}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Selecteer de opleiding"
              name="education"
              rules={[{ required: true, message: 'Dit is een verplicht veld' }]}
            >
              <Select
                showSearch
                suffixIcon={(
                  <CaretDownOutlined style={{
                    color: '#fff', fontSize: 25, top: -7, left: -15, position: 'relative',
                  }}
                  />
                )}
                optionFilterProp="children"
                filterOption={filterOption}
              >
                {educations.map((education) => (
                  <Select.Option key={education.id} value={education.id}>
                    {education.name || ''}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Row gutter={10}>
              <Col xs={12}>
                <Form.Item
                  label="Begin datum"
                  name="beginDate"
                  rules={[{ required: true, message: 'Dit is een verplicht veld' }]}
                >
                  <DatePicker
                    className="datepickerStyle"
                    format="MMMM YYYY"
                    picker="month"
                  />
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item
                  label="Eind datum (verwacht)"
                  name="endDate"
                  rules={[{ required: true, message: 'Dit is een verplicht veld' }]}
                >
                  <DatePicker
                    className="datepickerStyle"
                    format="MMMM YYYY"
                    picker="month"
                  />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}

        <Form.Item
          label="Heb je deze studie afgerond?"
          name="finished"
          rules={[{ required: true, message: 'Dit is een verplicht veld' }]}
        >
          <Radio.Group className="checkbox-normal">
            <Row gutter={10}>
              <Col xs={12} flex="0 1 150px">
                <Radio value>Ja</Radio>
              </Col>
              <Col xs={12} flex="0 1 150px">
                <Radio value={false}>Nee</Radio>
              </Col>
            </Row>
          </Radio.Group>
        </Form.Item>

        {finished === false
          ? (
            <>
              <Form.Item
                label="Loop je nominaal?"
                name="nominal"
                rules={[{ required: true, message: 'Dit is een verplicht veld' }]}
              >
                <Radio.Group className="checkbox-normal">
                  <Row gutter={10}>
                    <Col xs={12} flex="0 1 150px">
                      <Radio value>Ja</Radio>
                    </Col>
                    <Col xs={12} flex="0 1 150px">
                      <Radio value={false}>Nee</Radio>
                    </Col>
                  </Row>
                </Radio.Group>
              </Form.Item>

              <p className="infoBlock">Als je net begonnen bent met je master en je nog geen studiepunten hebt terug gekregen, vul dan 0 ects in met een 7,5 als gemiddeld cijfer</p>

              {!nominal ? (
                <Form.Item
                  label="Hoeveel maanden achterstand heb je?"
                  name="monthsBehind"
                  rules={[{ required: true, message: 'Dit is een verplicht veld' }]}
                >
                  <InputNumber type="number" min={0} />
                </Form.Item>
              ) : null}

              <Form.Item
                label="Hoeveel studiepunten heb je tot nu toe officieel behaald voor deze opleiding?"
                name="numberOfECs"
                rules={[{ required: true, message: 'Dit is een verplicht veld' }]}
              >
                <InputNumber type="number" />
              </Form.Item>
            </>
          )
          : <></>}

        <Form.Item
          label="Wat is je gemiddelde cijfer?"
          name="averageMark"
          rules={[{ required: true, message: 'Dit is een verplicht veld' }]}
        >
          <Input type="number" />
        </Form.Item>

        <Form.Item className="form-item-checkbox" name="partiallyAbroad" valuePropName="checked">
          <Checkbox>Als onderdeel van deze studie heb ik (deels) in het buitenland gestudeerd</Checkbox>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            Volgende
          </Button>
        </Form.Item>

        <Form.Item>
          <Button block onClick={onCancel}>
            Annuleren
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default StudiesModal;

import React from 'react';
import { Link, navigate } from '@reach/router';
import {
  Button,
  Col, Form,
  Row,
} from 'antd';
import moment, { Moment } from 'moment';
import { Breadcrumb, Layout, TodoPanel } from '..';
import { useAuth } from '../../hooks';

import styles from './profile.module.css';

export const Schedule = ({ source }: { source: { hoursPerWeek: string | number, beginDate?: Date | Moment, endDate?: Date | Moment } }) => (
  <p>

    {!!source.beginDate && !!source.endDate && (
      <span>
        {moment(source.endDate).diff(moment(source.beginDate), 'months', false)}
        {' '}
        maanden | &nbsp;
      </span>
    )}

    <span>
      {source.hoursPerWeek}
      {' '}
      uren per week
    </span>
  </p>
);

const scale = {
  NATIONAL: 'Nationaal',
  INTERNATIONAL: 'Internationaal',
  REGIONAL: 'Regionaal',
};

const boardType = {
  STUDENT_ASSOCIATION: 'Studentenvereniging',
  STUDY_ASSOCIATION: 'Studievereniging',
  FOUNDATION: 'Stichting',
  ORGANIZATION: 'Organisatie',
  DISPUTATION: 'Dispuut',
  MASTER_BOARD: 'Master bestuur',
  OTHER_ASSOCIATION: 'Verenigingsbestuur',
};

const Profile = () => {
  const { me, updateMe } = useAuth();

  const editProfile = async () => {
    await updateMe({
      variables: {
        input: {
          step: null,
        },
      },
    });
    navigate('/student/profiel-invullen');
  };

  const allEmpty = (propNames: string[]) => propNames.every((propName: string) => {
    if (!me) return true;
    if (Array.isArray(me[propName])) return me[propName].length === 0;
    return me[propName] === null;
  });

  return (
    <Layout>
      <div className={`${styles.profile} container`}>
        <Breadcrumb />
        <h2 style={{ marginTop: 20 }}>Mijn profiel</h2>

        {(me?.interestingIndustries ?? []).length ? (
          <>
            <Row gutter={20}>
              <Col lg={8} md={12}>
                <Form.Item>
                  <Button block onClick={editProfile}>
                    Profiel bewerken
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </>
        ) : (
          <TodoPanel
            title="Maak snel je profiel aan!"
            description="Op basis van je profiel kunnen wij de beste match voor je zoeken."
          >
            <Button
              style={{
                width: '100%', maxWidth: 400, margin: 'auto', display: 'block',
              }}
              href="/student/profiel-invullen/"
              type="primary"
            >
              Maak profiel aan
            </Button>
          </TodoPanel>
        )}

        {me && (
          <Row gutter={20}>
            <Col md={12} lg={8}>
              <div className={styles.block}>
                <h3>
                  <span className="turquoise">Jij!</span>
                </h3>
                <div className={styles.item}>
                  <p>
                    Naam:
                    {' '}
                    <b>
                      {me.firstName}
                      {' '}
                      {me.lastName}
                    </b>
                    {' '}
                  </p>
                  <p>
                    E-mailadres:
                    {' '}
                    <b>{me.email}</b>
                  </p>
                  {me.streetName && (
                    <p>
                      Adres:
                      {' '}
                      <b>
                        {me.streetName}
                        {' '}
                        {me.houseNumber}
                        ,
                        {' '}
                        {me.postalCode}
                        {' '}
                        {me.city}
                      </b>
                    </p>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        )}

        {me && (
          <>
            <p className={styles.subTitle}>
              <b>Opleiding en werkervaring</b>
            </p>
            {allEmpty(['studies', 'jobs']) && <p>Nog geen ervaring</p>}

            <Row gutter={20}>
              {me.highSchoolStartYear && (
                <Col lg={8} md={12}>
                  <Link to="/student/profiel-invullen/middelbare-school" className={styles.block}>
                    <h3>
                      <span className="turquoise">Middelbare school</span>
                    </h3>
                    <div className={styles.item}>
                      <p>
                        {me.highSchoolStartYear}
                        {' '}
                        -
                        {' '}
                        {me.highSchoolEndYear}
                      </p>
                      <p>
                        Gemiddeld cijfer:
                        {' '}
                        <b>{me.highSchoolAverageMark}</b>
                      </p>
                    </div>
                  </Link>
                </Col>
              )}

              {me.studies?.length ? (
                <Col lg={8} md={12}>
                  <Link to="/student/profiel-invullen/studie" className={styles.block}>
                    <h3>
                      <span className="turquoise">Studie</span>
                    </h3>
                    {me.studies.map((study, i) => (
                      <div className={styles.item} key={i}>
                        {study.type === 'OTHERWISE' ? (
                          <>
                            <p>
                              <b>{study.educationName}</b>
                            </p>
                            <p>{study.educationalInstitutionName}</p>
                          </>
                        ) : (
                          <>
                            <p>
                              <b>{study.education.name}</b>
                            </p>
                            <p>{study.educationalInstitution.name}</p>
                          </>
                        )}
                        {study.finished ? (
                          <p>
                            Behaald op:
                            {' '}
                            <b>{moment(study.endDate).format('MMMM YYYY')}</b>
                          </p>
                        ) : (
                          <p>
                            Behaald aantal ECTs:
                            {' '}
                            {study.numberOfECs}
                          </p>
                        )}
                        <p>{study.partiallyAbroad && 'Dit was gedeeltelijk in het buitenland'}</p>
                      </div>
                    ))}
                  </Link>
                </Col>
              ) : null}

              {me.jobs?.length ? (
                <Col lg={8} md={12}>
                  <Link to="/student/profiel-invullen/werkervaring" className={styles.block}>
                    <h3>
                      <span className="turquoise">Werkervaring</span>
                    </h3>
                    {me.jobs.map((job, i) => (
                      <div className={styles.item} key={i}>
                        <p>
                          <b>{job.jobTitle}</b>
                        </p>
                        <p>{job.companyName}</p>
                        <Schedule source={job} />
                        <p>{job.partiallyAbroad && 'Dit was gedeeltelijk in het buitenland'}</p>
                      </div>
                    ))}
                  </Link>
                </Col>
              ) : null}
            </Row>

            <p className={styles.subTitle}>
              <b>Nevenactiviteiten</b>
            </p>
            {allEmpty(['committees', 'topSport', 'ownBusiness', 'volunteerWork', 'studentTeam', 'boards']) && (
              <p>Nog geen activiteiten toegevoegd</p>
            )}

            <Row gutter={20}>
              {me.committees?.length ? (
                <Col lg={8} md={12}>
                  <Link to="/student/profiel-invullen/commissiewerk" className={styles.block}>
                    <h3>
                      <span className="turquoise">Commissie werk</span>
                    </h3>
                    {me.committees.map((committee, i) => (
                      <div className={styles.item} key={i}>
                        <p>
                          <b>{committee.association}</b>
                        </p>
                        <p>{committee.name}</p>
                        <p>{committee.position}</p>
                        <Schedule source={committee} />
                      </div>
                    ))}
                  </Link>
                </Col>
              ) : null}

              {me.boards?.length ? (
                <Col lg={8} md={12}>
                  <Link to="/student/profiel-invullen/bestuur" className={styles.block}>
                    <h3>
                      <span className="turquoise">Bestuur</span>
                    </h3>
                    {me.boards.map((board, i) => (
                      <div key={i} className={styles.item}>
                        <p>
                          <b>{boardType[board.type]}</b>
                        </p>
                        <p>{board.name}</p>
                        <p>{board.position}</p>
                        <Schedule source={board} />
                      </div>
                    ))}
                  </Link>
                </Col>
              ) : null}

              {me.topSport && (
                <Col lg={8} md={12}>
                  <Link to="/student/profiel-invullen/topsport" className={styles.block}>
                    <h3>
                      <span className="turquoise">Topsport</span>
                    </h3>
                    <div className={styles.item}>
                      <p>
                        <b>{me.topSport.associationName}</b>
                      </p>
                      <p>{me.topSport.type}</p>
                      <p>{scale[me.topSport.scale]}</p>
                    </div>
                  </Link>
                </Col>
              )}

              {me.ownBusiness && (
                <Col lg={8} md={12}>
                  <Link to="/student/profiel-invullen/eigen-bedrijf" className={styles.block}>
                    <h3>
                      <span className="turquoise">Eigen bedrijf</span>
                    </h3>
                    <div className={styles.item}>
                      <p>
                        <b>{me.ownBusiness.name}</b>
                      </p>
                      <Schedule source={me.ownBusiness} />
                    </div>
                  </Link>
                </Col>
              )}

              {me.volunteerWork?.map((volunteerWork) => (
                <Col lg={8} md={12}>
                  <Link to="/student/profiel-invullen/vrijwilligerswerk" className={styles.block}>
                    <h3>
                      <span className="turquoise">Vrijwilligerswerk</span>
                    </h3>
                    <div className={styles.item}>
                      <p>
                        <b>{volunteerWork.associationName}</b>
                      </p>
                      <p>{volunteerWork.position}</p>

                      <p>
                        {volunteerWork.hoursPerMonth}
                        {' '}
                        uren per maand
                      </p>
                    </div>
                  </Link>
                </Col>
              ))}

              {me.studentTeam?.map((studentTeam) => (
                <Col lg={8} md={12}>
                  <Link to="/student/profiel-invullen/studententeam" className={styles.block}>
                    <h3>
                      <span className="turquoise">Studententeam</span>
                    </h3>
                    <div className={styles.item}>
                      <p>
                        <b>{studentTeam.name}</b>
                      </p>
                      <p>{studentTeam.position}</p>
                      <Schedule source={studentTeam} />
                    </div>
                  </Link>
                </Col>
              ))}
            </Row>

            <p className={styles.subTitle}>
              <b>Vaardigheden en interesses</b>
            </p>
            {allEmpty(['skills', 'interestingIndustries']) && <p>Nog geen vaardigheden of interesses toegevoegd</p>}

            <Row gutter={20}>
              {me.skills?.length ? (
                <Col lg={8} md={12}>
                  <Link to="/student/profiel-invullen/vaardigheden" className={styles.block}>
                    <h3>
                      <span className="turquoise">Vaardigheden</span>
                    </h3>
                    <div className={styles.item}>
                      <p>{me.skills.map((skill) => skill.name).join(', ')}</p>
                    </div>
                  </Link>
                </Col>
              ) : null}

              {me.interestingIndustries?.length ? (
                <Col lg={8} md={12}>
                  <Link to="/student/profiel-invullen/sectoren" className={styles.block}>
                    <h3>
                      <span className="turquoise">Interessante sectoren</span>
                    </h3>
                    <div className={styles.item}>
                      <p>
                        De sectoren die jij hebt gekozen:
                        {' '}
                        {me.interestingIndustries.map((industry) => industry.name).join(', ')}
                      </p>
                    </div>
                  </Link>
                </Col>
              ) : null}
            </Row>

            <Row gutter={20}>
              {me.linkedInProfile && (
                <Col lg={8} md={12}>
                  <Link to="/student/profiel-invullen/linkedin" className={styles.block}>
                    <h3>
                      <span className="turquoise">LinkedIn</span>
                    </h3>
                    <div className={styles.item}>
                      <p>
                        Jouw LinkedIn profiel:
                        {' '}
                        <b>{me.linkedInProfile}</b>
                      </p>
                    </div>
                  </Link>
                </Col>
              )}
            </Row>
          </>
        )}

        {me?.interestingIndustries && (
          <Row>
            <Col lg={8} md={12}>
              <Form.Item>
                <Button block onClick={editProfile}>
                  Profiel bewerken
                </Button>
              </Form.Item>
            </Col>
          </Row>
        )}
      </div>
    </Layout>
  );
};

export default Profile;
